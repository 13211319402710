<template>
  <div>
    <b-card title="✨ Welcome to Abracadalo!">
      <b-card-text>
				<iframe width="560" height="295" style="max-width:100%" src="https://www.youtube.com/embed/D3I8RY4jFSc" title="Getting Started with Abracadalo | Part 1: The Abracadalo Dashboard" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <br>
        <br>
        <h4>Create Your First API Request!</h4>
				<p><a href="https://docs.abracadalo.com/create-request" target="_blank">Click here</a> to learn how to set up your first API request.</p>
			</b-card-text>
    </b-card>
    <b-card title="🎥 Watch our Tutorial Series on YouTube">
      <b-card-text>
        <a href="https://www.youtube.com/channel/UC2AAFcTpzAxsmuyjcF2Hr0w/featured" target="_blank">
				  <img :src="require('@/assets/images/banner/tutorials.png')" alt="Discover our Tutorials" style="max-width:100%">
        </a>
			</b-card-text>
    </b-card>
    <b-card title="❤️ Support us">
      <b-card-text>
        <p>Got a second? We would appreciate you giving us an UPVOTE on Product Hunt and leaving a review to help us grow. 🚀</p>
        <a href="https://www.producthunt.com/posts/abracadalo?utm_source=badge-review&utm_medium=badge&utm_souce=badge-abracadalo#discussion-body" target="_blank">
          <img :src="productHuntImageURL" alt="Abracadalo on Product Hunt" />
        </a>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  computed: {
    productHuntImageURL() {
      return 'https://api.producthunt.com/widgets/embed-image/v1/review.svg?post_id=342455&theme=' + store.state.appConfig.layout.skin
    },
  }
}
</script>

<style>

</style>
